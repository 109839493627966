<template>
  <div class="page">
    <div class="page-header">
      <el-button type="primary"
                 :disabled="!editable"
                 size="small" @click="endDiagnostic">
        {{ $t('diagnostics.endDiagnostic') }}
      </el-button>
    </div>
    <div class="page-main">
      <div class="info">
        <div class="modelPicture">
          <img :src="require('@/assets/images/' + this.$route.query.model + '.png')" alt=""/>
        </div>
        <div class="modelInfo">
          <div>
            <p>
              {{ $t('diagnostics.deviceName') + ':' }}
              <span>{{ deviceInfo.deviceName }}</span>
            </p>
          </div>
          <div>
            <p>
              {{ $t('diagnostics.macAddress') + ':' }}
              <span>{{ deviceInfo.macAddress }}</span>
            </p>
            <p>
              {{ $t('diagnostics.publicIP') + ':' }}
              <span>{{ deviceInfo.publicIp }}</span>
            </p>
          </div>
          <div>
            <p>
              {{ $t('diagnostics.model') + ':' }}
              <span>{{ deviceInfo.deviceModel }}</span>
            </p>
            <p>
              {{ $t('diagnostics.privateIP') + ':' }}
              <span>{{ deviceInfo.privateIp }}</span>
            </p>
          </div>
        </div>
        <div class="i" @click="goDeviceDetails" style="cursor:pointer">
          <i class="el-icon-warning-outline"></i>
        </div>
      </div>
      <div class="operation">
        <el-tabs v-model="activeName" @tab-click="tabClick">
          <el-tab-pane
            :label="$t('diagnostics.Ping/Traceroute')"
            name="Ping/Traceroute"
          >
            <pingTraceroute
              ref="pingTraceroute"
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
            ></pingTraceroute>
          </el-tab-pane>
          <el-tab-pane :label="$t('diagnostics.Syslog')" name="Syslog">
            <syslog
              ref="syslog"
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
            ></syslog>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('diagnostics.CaptureTrace')"
            name="captureTrace"
          >
            <captureTrace
              ref="captureTrace"
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
            ></captureTrace>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('diagnostics.SystemStatus')"
            name="systemStatus"
          >
            <systemStatus
              ref="systemStatus"
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
            >
            </systemStatus>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('diagnostics.ExportSystemLog')"
            name="exportSystemLog"
          >

            <exportSystemLog
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
              ref="exportSystemLog"
            ></exportSystemLog>
          </el-tab-pane>
          <!-- <el-tab-pane
            :label="$t('diagnostics.RecordingFile')"
            name="RecordingFile"
          >
            78787
          </el-tab-pane> -->
          <el-tab-pane
            :label="$t('diagnostics.ScreenCapture')"
            name="ScreenCapture"
          >
            <screenCapture
              ref="screenCapture"
              v-bind:use-old-data="useOldData"
              v-bind:editable="editable"
              v-bind:rec-data="recData"
              v-bind:token="token"
            ></screenCapture>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import pingTraceroute from './operation/pingTraceroute.vue'
import syslog from './operation/syslog.vue'
import captureTrace from './operation/captureTrace.vue'
import systemStatus from './operation/systemStatus.vue'
import exportSystemLog from './operation/exportSystemLog.vue'
import screenCapture from './operation/screenCapture.vue'
import axios from '@/plugins/axios.js'

export default {
  name: 'diagnosisInterface',
  components: {
    pingTraceroute,
    syslog,
    captureTrace,
    systemStatus,
    exportSystemLog,
    screenCapture,
  },
  data() {
    return {
      activeName: 'Ping/Traceroute',
      recData: null,
      useOldData: false,
      editable: false,
      token: '',
      deviceInfo: {},
    }
  },
  methods: {
    //结束诊断
    endDiagnostic() {
      {
        let params = {
          tokens: [this.$route.query.token],
        }
        axios.post('/diagnostics/finish', params).then(() => {
          this.editable = false
          this.$router.push({
            path: '/diagnostics',
            query: {},
          })

        })
      }
    },
    //转设备详情
    goDeviceDetails() {
      this.$router.push({
        path: '/device/deviceDetailInfo',
        query: {
          id: this.deviceInfo.id,
          subtitle: this.deviceInfo.macAddress
        }
      })
    },
    tabClick(tabClicked) {
      console.log(tabClicked)
      let name = tabClicked.name
      console.log(name)
      switch (name) {
        case 'Ping/Traceroute':this.$refs.pingTraceroute.refreshData();break;
        case 'Syslog':this.$refs.syslog.refreshData();break;
        case 'captureTrace':this.$refs.captureTrace.refreshData();break;
        case 'systemStatus':this.$refs.systemStatus.refreshData();break;
        case 'exportSystemLog':this.$refs.exportSystemLog.refreshData();break;
        case 'ScreenCapture':this.$refs.screenCapture.refreshData();break;
      }
    }
  },
  mounted() {
    this.useOldData = this.$route.query.useOldData === 'true'
    this.editable = this.$route.query.editable === 'true'
    this.token = this.$route.query.token
    axios.get('/diagnostics/device/info/' + this.$route.query.token)
      .then((data) => {
        this.deviceInfo = data.data.result.rows[0]
      })
  }
}
</script>

<style lang="scss" scoped>
.page-main {
  padding: 30px 10px 0 30px;
}

.info {
  padding: 10px 10px 10px 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding-left: 10px;

  .modelPicture {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: white;
    margin-right: 30px;
    vertical-align: middle;

    img {
      height: 110px;
      width: 110px;
    }
  }

  .modelInfo {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    margin-top: 15px;

    p {
      display: inline-block;
      margin: 0;
      width: 300px;
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .i {
    display: inline-block;
    vertical-align: middle;
    width: 10%;

    i {
      font-size: 50px;
      color: #6b489d;
      margin-top: 20px;
    }
  }
}

.operation {
  margin-top: 20px;

  .el-tab-pane {
    padding: 0px;
    padding-top: 30px;
  }
}
</style>
