<template>
  <div class="exportSystemLog">
<!--    <span class="dot"></span>-->
<!--    <span class="subhead">{{ $t('diagnostics.7daylog') }}</span>-->
    <div class="content">
<!--      <el-date-picker-->
<!--        v-model="value1"-->
<!--        type="datetimerange"-->
<!--        range-separator="至"-->
<!--        start-placeholder="开始日期"-->
<!--        end-placeholder="结束日期"-->
<!--      >-->
<!--      </el-date-picker>-->
      <el-button type="primary"
                 :disabled="!editable"
                 style="margin-left: 20px" @click="exportFile">{{
        $t('diagnostics.export')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import {downloadFile} from '@/plugins/methods'

export default {
  name: 'exportSystemLog',
  props: {
    useOldData: Boolean,
    editable: Boolean,
    recData: String,
    token: String,
  },
  methods: {
    exportFile() {
      let params = {
        typeCode: ['7'],
        token: this.token,
      }
      axios.post('/diagnostics/start', params).then((data) => {
        let subData = data.data.result.rows[0].recDTOMap.consistent_log[0]
        this.smallToken = subData.token
        if (subData.errorInfo) {
          this.$message.error(subData.errorInfo)
        } else {
          this.showStart = false
          this.showEnd = true
          axios.get('/diagnostics/file/' + this.smallToken, {responseType: 'blob'}).then((res) => {
            downloadFile(res)
          })
        }
      })
    },
    refreshData(){

    }
  },
}
</script>

<style lang='scss' scoped>
.dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
.subhead {
  font-size: 14px;
  vertical-align: middle;
}
.content {
  margin-top: 10px;
}
</style>