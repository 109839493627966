<template>
  <div class="screenCapture">
    <div class="capture">
      <span class="dot"></span>
      <span class="subhead">{{ $t('diagnostics.screenCapture') }}</span>
      <div style="margin-top: 10px">
        <!-- 开始 -->
        <div style="height: 50px">
          <el-button
            :disabled="!editable || !startEnable"
            type="primary" @click="start">{{ $t('diagnostics.start') }}
          </el-button>
          <span v-show="showObtaining"> {{ $t('diagnostics.Obtaining') }}</span>
        </div>
      </div>
    </div>
    <div class="ScreenCaptureFiles">
      <span class="dot"></span>
      <span class="subhead">{{ $t('diagnostics.ScreenCaptureFiles') }}</span>
      <div style="margin-top: 10px; margin-bottom: 10px">
        <el-button type="primary" @click="deleteLogs" :disabled="selected==null||selected.length==0||!editable"> {{ $t('diagnostics.delete') }}</el-button>
      </div>
      <table-pagination
        memory="screenCapture"
        ref="refTable"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
        :height="pageTabelHeight/2+60"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('diagnostics.download')"
            placement="top"
            :open-delay="1000"
          >
            <el-button type="text" class="btn-p0" @click="download(scope.row.token)">
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
  </div>
</template>

<script>
import {TablePagination} from '@/components'
import axios from '@/plugins/axios'
import {downloadFile} from '@/plugins/methods'
import {diagnostics} from '@/plugins/enums'
import {mapGetters} from 'vuex'

export default {
  name: 'screenCapture',
  components: {
    TablePagination,
  },
  props: {
    useOldData: Boolean,
    editable: Boolean,
    recData: String,
    token: String,
  },
  computed:{
    ...mapGetters('session', ['pageTabelHeight']),
  },
  data() {
    return {
      headData: null,
      tableData: [],
      columnData: [
        {
          label: this.$t('diagnostics.creationTime'),
          prop: 'createTime',
          minWidth: 180,
          filter: 'dateFormat'
        },
        {
          label: this.$t('diagnostics.fileName'),
          prop: 'fileName',
          minWidth: 200,
        },
        {
          label: this.$t('diagnostics.fileSize'),
          prop: 'fileSize',
          minWidth: 180,
        },
      ],
      tabelTotal: 0,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        //TODO确认sortField字段
        sortField: 'creationTime',
        sortOrder: 'asc',
        keywords: null,
      },
      smallToken: null,
      selected: [],
      showObtaining: false,
      startEnable :true
    }
  },
  methods: {
    //开始
    async start() {
      let params = {
        typeCode: ['3'],
        token: this.token,
      }
      axios.post('/diagnostics/start', params).then((data) => {
        let subData = data.data.result.rows[0].recDTOMap.screen[0]
        this.smallToken = subData.token
        if (subData.errorInfo) {
          this.$message.error(subData.errorInfo)
        } else {
          this.showObtaining = true
          this.startEnable = false
        }
      })
      await this.sleep(8000)
      this.startEnable = true
      this.refreshData()
    },
    //结束
    end() {
      let params = {
        tokens: [this.smallToken],
      }
      axios.post('/diagnostics/finish', params).then(() => {
        this.refreshData()
      })
    },
    //删除设备日志
    deleteLogs() {
      let parameter = {
        'tokens': this.selected.map((row) => {
          return row.token
        })
      }
      axios.post('/diagnostics/delete', parameter).then(
        () => {
          this.refreshData()
        }
      )
    },
    //下载文件
    download(token) {
      axios.get('/diagnostics/file/' + token, {responseType: 'blob'}).then((res) => {
        downloadFile(res)
      })
    },
    selectionChange(val) {
      this.selected = val
    },
    tabelSortChange() {
    },
    getTabelData() {
      this.refreshData()
    },
    refreshData() {
      // 刷新历史列表
      let params = {
        token: this.$route.query.token,
        typeCode: [diagnostics().type.screen.code],
        status: [3, 5, 7],
        pageInfo: {
          pageSize: this.tabelSearch.pageSize,
          pageCurrent: this.tabelSearch.currentPage,
        },
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params).then((data) => {
          if (data.data.result.rows[0]) {
            this.tableData = data.data.result.rows[0].recDTOMap.screen
            this.tabelTotal = data.data.result.totalRows
          }
        })
      }
      this.showObtaining = false
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
  mounted() {
    this.refreshData()
  },
}
</script>

<style lang="scss" scoped>
.dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.subhead {
  font-size: 14px;
  vertical-align: middle;
}

::v-deep .el-progress-bar__outer {
  border-radius: 4px;
}

::v-deep .el-progress-bar__inner {
  border-radius: 0;
}

.Capturing {
  position: relative;
  display: inline-block;
  width: 150px;
  margin-left: 10px;

  .T {
    color: white;
    font-size: 14px;
    width: 180px;
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
  }
}
</style>
