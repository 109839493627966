<template>
  <div class="pingTraceroute">
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('diagnostics.Operation')" prop="Operation">
        <el-select v-model="form.Operation" :placeholder="$t('pleaseSelect')" style="width: 60%">
          <el-option :label="$t('diagnostics.ping')" value="Ping"></el-option>
          <el-option :label="$t('diagnostics.traceroute')" value="Traceroute"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('diagnostics.TargetHost')" prop="TargetHost">
        <el-input
          v-model="form.TargetHost"
          :placeholder="$t('diagnostics.TargetHostPlacehold')"
          style="width: 60%"
        ></el-input>
        <el-button
          v-show="!running"
          :disabled="!editable || disableStart"
          style="margin-left: 20px" type="primary" @click="start">{{
            $t('diagnostics.start')
          }}
        </el-button>
        <el-button
          v-show="running"
          :disabled="!editable || disableStop"
          style="margin-left: 20px" type="primary" @click="stop">{{
            $t('diagnostics.end')
          }}
        </el-button>
        <el-button :disabled="!editable" @click="clear">{{ $t('diagnostics.clear') }}</el-button>
      </el-form-item>
    </el-form>
    <div class="showResults">
      <p v-html="result"></p>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import {diagnostics} from '@/plugins/enums'

export default {
  name: 'pingTraceroute',
  props: {
    useOldData: Boolean,
    editable: Boolean,
    recData: String,
    token: String
  },
  data() {
    return {
      form: {
        Operation: '',
        TargetHost: '',
      },
      rules: {
        Operation: [
          {
            required: true,
            message: this.$t('diagnostics.OperationRule'),
            trigger: 'blur',
          },
        ],
        TargetHost: [
          {
            required: true,
            message: this.$t('diagnostics.TargetHostRule'),
            trigger: 'blur',
          },
        ],
      },
      result: '',
      smallToken: '',
      running: false,
      realTimeMode: false,
      macSuffix: null,
      task: null,
      disableStart: false,
      disableStop: false,
    }
  },
  methods: {
    //开始
    start() {
      this.disableStart = true
      this.disableStop = true
      if (!this.form.Operation || !this.form.TargetHost) {
        this.$message.error(this.$t('diagnostics.blankPrompt'))
        return
      }

      let params = {
        'typeCode': [],
        'macOrIpList': [],
        'token': this.$route.query.token,
        'targetIp': this.form.TargetHost,
      }
      if (!params.targetIp || params.targetIp == '') {
        this.$message.error(this.$t('diagnostics.pingBlankPrompt'))
        return
      }

      this.running = true

      if (this.form.Operation === 'Ping') {
        params.typeCode.push('4')
        axios.post('/diagnostics/start', params)
          .then((data) => {
            this.smallToken = ((data.data.result.rows)[0]).recDTOMap.ping[0].token
            this.realTimeMode = true
            this.realTimeTask(this.macSuffix, 'ping')
          })
          .catch(() => {
            this.running = false
            this.disableStart = false
            this.disableStop = false
          })
      } else if (this.form.Operation === 'Traceroute') {
        params.typeCode.push('5')
        params.noLoading = true
        axios.post('/diagnostics/start', params)
          .then((data) => {
            this.smallToken = ((data.data.result.rows)[0]).recDTOMap.traceroute[0].token
            this.realTimeMode = true
            this.realTimeTask(this.macSuffix, 'traceroute')
          })
          .catch(() => {
            this.running = false
            this.disableStart = false
            this.disableStop = false
          })
      }
    },
    getMacSuffix() {
      axios.get('/diagnostics/device/info/' + this.$route.query.token)
        .then((data) => {
          let mac = (data.data.result.rows[0]).macAddress
          this.macSuffix = mac.substring(9, mac.length).split(':').join('').toUpperCase()
        })
    },
    async realTimeTask(macSuffix, type) {
      this.result = ''
      console.log('real time task. type:' + type + ', macSuffix:' + macSuffix)
      // 循环请求获取内容，直到某个条件结束
      var task = setInterval(() => {
        this.realTimeHttp(macSuffix, type)
      }, 2000)
      this.task = task
      this.clearTask()
    },
    async clearTask() {
      if (this.task) {
        for (let i = 0; i < 100; i++) {
          if (this.realTimeMode == false) {
            clearInterval(this.task)
            break
          }
          await this.sleep(1000)
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async realTimeHttp(macSuffix, type) {
      let param = {
        data: {
          noLoading: true
        }
      }
      axios.get('/diagnostics/realtime/' + type + '/' + macSuffix, param)
        .then((data) => {
          this.result = data.data.result.rows[0]
          if (this.result == 'finish') {
            console.log('request for result of finish. stop task and refreshData')
            this.realTimeMode = false
            this.running = false
            this.refreshData()
          } else {
            this.disableStop = false
            console.log('request for result and continue running task. result:+' + this.result)
          }
        })
    },
    stop() {
      clearInterval(this.task)
      this.running = false
      let params = {
        tokens: [this.smallToken]
      }
      axios.post('/diagnostics/finish', params)
        .then(() => {
          this.refreshData()
        })
    },
    //清除
    clear() {
      let params = {
        'token': this.$route.query.token,
        'typeCode': [diagnostics().type.ping.code, diagnostics().type.traceroute.code],
        'status': [3, 5, 7],
        'mix': true,
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params)
          .then((data) => {
            if ((data.data.result.rows)[0]) {
              let recsTokens = (data.data.result.rows)[0].recTokens
              let parameter = {
                'tokens': recsTokens
              }
              axios.post('/diagnostics/delete', parameter).then(
                () => {
                  this.refreshData()
                }
              )
            }
          })
      }
    },
    async refreshData() {
      console.log('refreshing data...')
      // 先将result置空
      this.result = null
      this.disableStart = false
      this.disableStop = false

      // 刷新历史
      let params = {
        'token': this.$route.query.token,
        'typeCode': [diagnostics().type.ping.code, diagnostics().type.traceroute.code],
        'status': [3, 5, 7],
        'mix': true,
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params)
          .then((data) => {
            this.getMacSuffix()
            if ((data.data.result.rows)[0]) {
              let jsonStr
              if (((data.data.result.rows)[0]).recDTOMap.ping) {
                jsonStr = ((data.data.result.rows)[0]).recDTOMap.ping[0].data
              } else if (((data.data.result.rows)[0]).recDTOMap.traceroute) {
                jsonStr = ((data.data.result.rows)[0]).recDTOMap.traceroute[0].data
              } else {
                jsonStr = '{}'
              }

              let json = JSON.parse(jsonStr)
              if (json.pingResult) {
                this.result = json.pingResult
              } else {
                this.result = json.tracerouteResult
              }
            }
          })
      }

    },
  },
  mounted() {
    this.refreshData()
  },

}
</script>

<style lang='scss' scoped>
.showResults {
  border: 1px dotted #ccc;
  border-radius: 8px;
  height: 400px;
  padding-left: 10px;
  white-space: pre-wrap;
  overflow: scroll;
  line-height: 150%;
  font-family: monospace;
  font-size: 15px;

  p {
    margin: 0;
  }
}

@include form-page;
div {

}

</style>
