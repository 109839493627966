<template>
  <div class="syslog">
    <div class="capture">
      <span class="dot"></span>
      <span class="subhead">{{ $t('diagnostics.CaptureLOG') }}</span>
      <span class="level"
      ><i class="el-icon-edit-outline"></i
      >{{ $t('diagnostics.logLevel') }}</span
      >
      <el-select v-model="lavelLabel" @change="levelchanged">
        <el-option
          v-for="item in levleOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <p>{{ $t('diagnostics.CaptureLOGTip') }}</p>
      <!-- processing -->
      <div style="height: 50px" v-show="isprocessing">
        <el-button type="primary">{{
            $t('diagnostics.processing')
          }}
        </el-button>
      </div>
      <!-- 开始 -->
      <div style="height: 50px"
           v-show="showStart">
        <el-button
          :disabled="!editable"
          type="primary" @click="start">{{
            $t('diagnostics.start')
          }}
        </el-button>
      </div>
      <!-- 结束 -->
      <div style=" position: relative;height: 50px" v-show="showEnd">
        <div style="position: absolute;top:0;left:0px">
          <el-button type="primary" @click="end">{{
              $t('diagnostics.end')
            }}
          </el-button>
        </div>
        <div class="Capturing" style="position: absolute;top:0;left:80px">
          <el-progress
            :text-inside="true"
            :show-text="false"
            :stroke-width="41"
            :percentage="70"
            stroke-linecap="square"
          ></el-progress>
          <span class="T"
          ><i class="el-icon-loading"></i>
          {{ $t('diagnostics.Capturing') }}</span
          >
        </div>
      </div>
    </div>
    <div class="Device">
      <span class="dot"></span>
      <span class="subhead">{{ $t('diagnostics.DeviceLogs') }}</span>
      <div style="margin-top: 10px; margin-bottom: 10px">
        <el-button type="primary" :disabled="selected==null||selected.length==0 || !editable" @click="deleteDeviceLogs">
          {{ $t('diagnostics.delete') }}
        </el-button
        >
      </div>
      <table-pagination
        memory="diagnosticsSyslog"
        ref="refTable"
        :tableData="tableData"
        :columnData="columnData"
        selectionShow
        @selection-change="selectionChange"
        optionShow
        columnOptionShow
        :total="tabelTotal"
        :currentPage.sync="tabelSearch.currentPage"
        :pageSize.sync="tabelSearch.pageSize"
        @changeCurrentPage="getTabelData"
        @sort-change="tabelSortChange"
        :height="pageTabelHeight/2-20"
      >
        <!-- option -->
        <template slot="option" slot-scope="scope">
          <el-tooltip
            effect="dark"
            :content="$t('diagnostics.download')"
            placement="top"
            :open-delay="1000"
          >
            <el-button
              type="text"
              class="btn-p0"
              @click="download(scope.row.token)"
            >
              <i class="el-icon-download"></i>
            </el-button>
          </el-tooltip>
        </template>
      </table-pagination>
    </div>
  </div>
</template>

<script>
import {TablePagination} from '@/components'
import axios from '@/plugins/axios'
import {diagnostics} from '@/plugins/enums'
import {downloadFile} from '@/plugins/methods'
import {mapGetters} from 'vuex'

export default {
  name: 'syslog',
  components: {
    TablePagination,
  },
  props: {
    useOldData: Boolean,
    editable: Boolean,
    recData: String,
    token: String
  },
  data() {
    return {
      lavelLabel: '',
      levelValue: '',
      levleOptions: [
        {
          label: this.$t('diagnostics.Emergency'),
          value: 1,
        },
        {
          label: this.$t('diagnostics.Error'),
          value: 2,
        },
        {
          label: this.$t('diagnostics.Warning'),
          value: 3,
        },
        {
          label: this.$t('diagnostics.Notice'),
          value: 4,
        },
        {
          label: this.$t('diagnostics.Informational'),
          value: 5,
        },
        {
          label: this.$t('diagnostics.Debug'),
          value: 6,
        },
      ],
      isprocessing: false,
      showStart: true,
      showEnd: false,
      headData: null,
      tableData: [],
      columnData: [
        {
          label: this.$t('diagnostics.creationTime'),
          prop: 'createTime',
          minWidth: 180,
          filter: 'dateFormat'
        },
        {
          label: this.$t('diagnostics.fileName'),
          prop: 'fileName',
          minWidth: 200,
        },
        {
          label: this.$t('diagnostics.fileSize'),
          prop: 'fileSize',
          minWidth: 180,
        },
      ],
      tabelTotal: 0,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        //TODO确认sortField字段
        sortField: 'creationTime', 
        sortOrder: 'asc',
        keywords: null,
      },
      square: 'square',
      smallToken: null,
      selected: []
    }
  },
  computed:{
    ...mapGetters('session', ['pageTabelHeight']),
  },
  methods: {
    //开始
    start() {
      let params = {
        'typeCode': ['2'],
        'macOrIpList': [],
        'token': this.token,
        'levelValue': this.levelValue
      }
      axios.post('/diagnostics/start', params)
        .then((data) => {
          let subData = ((data.data.result.rows[0]).recDTOMap.syslog)[0]
          if (subData.errorInfo) {
            this.$message.error(subData.errorInfo)
          }
          this.refreshData()
        })
    },
    //结束
    end() {
      let params = {
        tokens: [this.smallToken]
      }
      axios.post('/diagnostics/finish', params)
        .then(() => {
          this.refreshData()
        })
    },
    //删除设备日志
    deleteDeviceLogs() {
      let parameter = {
        'tokens': this.selected.map((row) => {
          return row.token
        })
      }
      axios.post('/diagnostics/delete', parameter).then(
        () => {
          this.refreshData()
        }
      )
    },
    //下载文件
    download(token) {
      axios.get('/diagnostics/file/' + token, {responseType: 'blob'}).then((res) => {
        downloadFile(res)
      })
    },
    selectionChange(val) {
      this.selected = val
    },
    tabelSortChange() {
    },
    getTabelData() {
      this.refreshData()
    },
    levelchanged(value) {
      this.levelValue = value
      axios.get('/diagnostics/device/info/' + this.$route.query.token)
        .then((data) => {
          let deviceId = data.data.result.rows[0].id
          axios.get('/common/setLogLevel/' + deviceId + '/' + this.levelValue)
            .then(() => {
              this.refreshData()
            })
        })
    },
    refreshData() {
      // 刷新历史列表
      let params = {
        'token': this.$route.query.token,
        'typeCode': [diagnostics().type.syslog.code],
        'status': [3, 5, 7],
        pageInfo: {
          pageSize: this.tabelSearch.pageSize,
          pageCurrent: this.tabelSearch.currentPage,
        },
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params)
          .then((data) => {
            if ((data.data.result.rows)[0]) {
              this.tableData = ((data.data.result.rows)[0]).recDTOMap.syslog
              this.tabelTotal = data.data.result.totalRows
            }
          })
      }

      // 刷新正在执行的
      params = {
        'token': this.$route.query.token,
        'typeCode': [diagnostics().type.syslog.code],
        'status': [diagnostics().status.process.code]
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params)
          .then((data) => {
            if (((data.data.result.rows)[0]).recDTOMap.syslog) {
              this.headData = (((data.data.result.rows)[0]).recDTOMap.syslog)[0]
              if (this.headData) {
                this.smallToken = this.headData.token
                if (this.headData.status == diagnostics().status.process.code) {
                  this.showEnd = true
                  this.showStart = false
                }
              }
            } else {
              this.showEnd = false
              this.showStart = true
            }
          })
      }

      // 获取logLevel
      if (this.editable) {
        axios.get('/diagnostics/logLevel/' + this.token)
          .then((body) => {
            this.levelValue = body.data.result.rows[0].logLevel
            this.lavelLabel = this.levleOptions[this.levelValue-1].label
          })
      }
    }
  },
  mounted() {
    this.useOldData = this.$route.query.useOldData === 'true'
    this.editable = this.$route.query.editable === 'true'
    this.token = this.$route.query.token
    if (this.editable) {
      this.refreshData()
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-progress-bar__outer {
  border-radius: 4px;
}

::v-deep .el-progress-bar__inner {
  border-radius: 0;
}

.dot {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: blue;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.subhead {
  font-size: 14px;
  vertical-align: middle;
}

.level {
  color: #6b489d;
  margin-left: 20px;
  font-size: 14px;

}

::v-deep .el-select .el-input__inner:focus,
::v-deep .el-input--suffix .el-input__inner {
  border: none;
}

::v-deep .pagination .el-input--suffix .el-input__inner {
  border-style: solid;
  border-width: 2px;
  border-color: #c0c4cc;


}

::v-deep .el-input__suffix,
::v-deep .el-input__suffix-inner {
  display: none;
}

.Capturing {
  position: relative;
  display: inline-block;
  width: 150px;
  margin-left: 10px;

  .T {
    color: white;
    font-size: 14px;
    width: 180px;
    position: absolute;
    z-index: 10000;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);

  }


}
</style>
