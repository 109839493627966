q<template>
  <div class="page">
    <div class="page-main">
      <div class="deviceSelection">
        <div class="title">{{ $t('diagnostics.deviceSelection') }}</div>
      </div>
      <div class="mac-list">
        <el-form class="mac-item" :rules="rules">
          <el-form-item class="el-inputs" prop="input">
            <el-input
              class="item-input"
              v-model="addresslist[0].Address"
              :placeholder="$t('diagnostics.searchPlaceHold')"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="start-button">
        <el-button
          type="primary"
          @click="start"
          :disabled="noDevice"
          :style="{
            backgroundColor: noDevice ? '#ccc' : '',
            borderColor: noDevice ? '#ccc' : '',
          }"
        >
          {{ $t('diagnostics.startDiagnostics') }}
        </el-button>
      </div>
      <div class="diagnosis-activity">
        <div class="h">
          <el-button
            :disabled="selected == null || selected.length == 0"
            type="primary"
            icon="el-icon-delete iconfont icon-delete"
            @click="deleteHistory"
          >{{ $t('diagnostics.delete') }}
          </el-button>
          <el-input
            v-model="keywords"
            :placeholder="$t('diagnostics.keywordsPla')"
            prefix-icon="el-icon-search"
            clearable
            @change="searchByKeys"
          ></el-input>
        </div>
        <table-pagination
          memory="diagnostics"
          ref="refTable"
          :tableData="tableData"
          :columnData="columnData"
          selectionShow
          @selection-change="selectionChange"
          optionShow
          columnOptionShow
          :total="tabelTotal"
          :height="pageTabelHeight-205"
          :currentPage.sync="tabelSearch.currentPage"
          :pageSize.sync="tabelSearch.pageSize"
          @changeCurrentPage="getTabelData"
          @sort-change="tabelSortChange"
        >

          <template slot="macAddress" slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="deviceStatus[scope.row.statusOfDevice]"
              placement="top"
            >
              <i
                class="stateDot"
                :class="
                scope.row.statusOfDevice == 1
                  ? 'bg-success'
                  : scope.row.statusOfDevice == 2
                  ? 'bg-danger'
                  : 'bg-info'
              "
              ></i>
            </el-tooltip>
            {{ scope.row.macAddress.toUpperCase() }}
          </template>

          <template slot="status" slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.status"
              placement="top"
            >
              <i v-if="scope.row.status == 'Diagnosing'"
                 class="el-icon-refresh"
              />
            </el-tooltip>
            {{ scope.row.status }}
          </template>
          <!-- option -->
          <template slot="option" slot-scope="scope">
            <el-tooltip
              v-if="scope.row.statusCode == '2'"
              effect="dark"
              :content="$t('diagnostics.continueDiagnostics')"
              placement="top"
              :open-delay="1000"
            >
              <el-button
                type="text"
                :disabled="scope.row.statusOfDevice!='1'"
                class="btn-p0"
                @click="continueDiagnostics(scope.row.id, scope.row.token, scope.row.model)"
              >
                <i class="el-icon-video-play iconfont icon-play"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.statusCode == '2'"
              effect="dark"
              :content="$t('diagnostics.endDiagnostics')"
              placement="top"
              :open-delay="1000"
            >
              <el-button type="text" class="btn-p0"
                         @click="endDiagnostics(scope.row.token)">
                <i class="el-icon-video-pause iconfont icon-switch"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.statusCode == '3'"
              effect="dark"
              :content="$t('diagnostics.diagnosticsAgain')"
              placement="top"
              :open-delay="1000"
            >
              <el-button
                type="text"
                class="btn-p0"
                @click="diagnosticsAgain(scope.row.macAddress)"
              >
                <i class="el-icon-first-aid-kit iconfont icon-strategy"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              v-if="scope.row.statusCode == '3'"
              effect="dark"
              :content="$t('diagnostics.viewDetails')"
              placement="top"
              :open-delay="1000"
            >
              <el-button
                type="text"
                class="btn-p0"
                @click="viewDetails(scope.row.id, scope.row.token, scope.row.model)"
              >
                <i class="el-icon-warning-outline iconfont icon-info"></i>
              </el-button>
            </el-tooltip>
          </template>
        </table-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {TablePagination} from '@/components'
import axios from '@/plugins/axios.js'
import session from '@/store/modules/session.js'
import {mapGetters} from 'vuex'

export default {
  name: 'Diagnostics',
  components: {
    TablePagination,
  },
  data() {
    return {
      addresslist: [
        {
          Address: '',
        },
      ],
      tableData: [
        {
          time: '111',
          statusCode: 'Diagnostics',
        },
        {
          time: '222',
          statusCode: 'Completed',
        },
      ],
      columnData: [
        {
          label: this.$t('diagnostics.time'),
          prop: 'createTime',
          sortable: 'custom',
          filter: 'dateFormat'
        },
        {
          label: this.$t('diagnostics.macAddress'),
          prop: 'macAddress',
          sortable: 'custom',
          filter: 'toUpperCase',
          slot: true
        },
        {
          label: this.$t('diagnostics.deviceName'),
          prop: 'deviceName',
        },
        {
          label: this.$t('diagnostics.model'),
          prop: 'model',
          filterEnable: true,
        },
        {
          label: this.$t('diagnostics.site'),
          prop: 'site',
        },
        {
          label: this.$t('diagnostics.user'),
          prop: 'username',
          filterEnable: true,
        },
        {
          label: this.$t('diagnostics.diagnosticStatus'),
          prop: 'status',
          sortable: 'custom',
          slot: true
        },
      ],
      tabelTotal: 0,
      tabelSearch: {
        currentPage: 1,
        pageSize: 10,
        sortField: 'createTime',
        sortOrder: 'desc',
        keywords: null,
      },
      keywords: '',
      dataList: [],
      selected: [],
      regex: new RegExp(
        // '((([0-9A-Fa-f]){2}([:-]+)){5}([0-9A-Fa-f]{2}))|(([0-9A-Fa-f]){6})'
        '(([0-9A-Fa-f]{2}([:-]?)){5})([0-9A-Fa-f]{2}([:-]?))'
      ),
      rules: {
        input: {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$t('diagnostics.blankPrompt')))
            }
          },
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    ...mapGetters('dist', ['deviceAccountStatus', 'deviceAccountStatus2', 'deviceStatus']),
    ...mapGetters('session', ['pageTabelHeight']),
    noDevice() {
      // 如果全部为空或者null，则不能start
      let badNum = 0
      for (let i = 0; i < this.addresslist.length; i++) {
        if (!this.addresslist[i].Address || this.addresslist[i].Address === '') {
          badNum = badNum + 1
        }
      }
      if (badNum === this.addresslist.length) {
        return true
      }

      // 如果有不为空的，则忽略掉空的之后，判断不为空的是否是ip或者mac。如果有任何一个不为空的str不满足正则，则不能start
      for (let i = 0; i < this.addresslist.length; i++) {
        let inputStr = this.addresslist[i].Address
        if (!inputStr || inputStr === '') {
          continue
        } else {
          if (!this.regex.exec(inputStr)) {
            return true
          }
        }
      }
      return false
    },
  },
  methods: {
    //input空白提示
    blankPrompt(i) {
      if (this.addresslist[i].Address == '') {
        this.$message.error(this.$t('diagnostics.blankPrompt'))
      }
    },
    //ADD
    addInput() {
      if (this.addresslist.length < 5) {
        this.addresslist.push({
          Address: '',
        })
      }
    },
    //delete
    deleteInput(i) {
      if (this.addresslist.length > 1) {
        this.addresslist.splice(i, 1)
      }
    },
    selectionChange(val) {
      this.selected = val
    },
    tabelSortChange({order, prop}) {
      this.tabelSearch.sortOrder = order ? order : 'desc'
      this.tabelSearch.sortField = order ? prop : 'createTime'
      if (this.tabelSearch.sortField === 'createTimeStr') {
        this.tabelSearch.sortField = 'createTime'
      }
      this.searchByKeys()
    },
    // 获取Diagnosis Activity中table数据
    getTabelData() {
      this.searchByKeys()
    },
    //继续诊断按钮
    continueDiagnostics(rowId, token, model) {
      this.$router.push({
        path: '/diagnostics/diagnosisInterface',
        query: {
          useOldData: true,
          editable: true,
          token: token,
          model: model
        },
      })
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    //结束诊断按钮
    async endDiagnostics(token) {
      let params = {
        tokens: [token],
      }
      axios.post('/diagnostics/finish', params).then(() => {
        session.state.axiosLoading = true
      })
      await this.sleep(2000)
      session.state.axiosLoading = false
      this.searchByKeys()
    },
    //
    diagnosticsAgain(macAddress) {
      let addresslist = [
        {
          'Address': macAddress,
        },
      ]
      this.startWithCertainSearchKeys(addresslist)
    },
    //诊断详情按钮
    viewDetails(rowId, token, model) {
      this.$router.push({
        path: '/diagnostics/diagnosisInterface',
        query: {
          useOldData: true,
          editable: false,
          token: token,
          model: model,
        },
      })
    },
    // 删除诊断历史记录
    deleteHistory() {
      let selected = this.selected
      if (selected && selected.length > 0) {
        let tokens = []
        for (let i = 0; i < selected.length; i++) {
          tokens.push(selected[i].token)
        }
        axios
          .post('/diagnostics/delete', {
            tokens: tokens,
          })
          .then(() => {
            this.searchByKeys()
          })
      }
    },
    //关键字搜素
    searchByKeys() {
      let params = {
        searchKey: this.keywords,
        orderByList: [],
        orderByDescList: [],
        model: 'generalALE',
        username: '',
        pageInfo: {
          pageSize: this.tabelSearch.pageSize,
          pageCurrent: this.tabelSearch.currentPage,
        },
        orderInfo: this.tabelSearch.sortField + ' ' + this.tabelSearch.sortOrder,
        mix: false,
        token: '',
      }
      axios.post('/diagnostics/read', params).then((data) => {
        this.tableData = data.data.result.rows
        this.tabelTotal = data.data.result.totalRows
      })
    },
    start() {
      this.startWithCertainSearchKeys(this.addresslist)
    },
    startWithCertainSearchKeys(addresslist) {
      let params = {
        typeCode: [],
        macOrIpList: [],
        token: '',
      }
      for (const a of addresslist) {
        params.macOrIpList.push(a.Address)
      }
      axios.post('/diagnostics/start', params).then((data) => {
        this.$router.push({
          path: '/diagnostics/diagnosisInterface',
          query: {
            useOldData: true,
            editable: true,
            token: data.data.result.rows[0].token,
            model: data.data.result.rows[0].model,
          },
        })
      })
    },
  },
  mounted() {
    this.searchByKeys()
    if (this.$route.query.mac) {
      this.addresslist[0].Address = this.$route.query.mac
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: #666;
  font-size: 20px;
}

.deviceSelection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
}

.mac-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mac-item {
    position: relative;
    width: 460px;
    margin-bottom: 30px;

    .el-inputs {
      margin-bottom: 0;

      .item-input {
        width: 100%;
      }

      .item-del {
        position: absolute;
        top: 12px;
        right: -50px;
      }
    }
  }

  .add {
    width: 460px;
    height: 54px;
  }

  .deleteButton {
    display: inline-block;
    width: 460px;
    margin-left: 5px;
  }
}

.diagnosis-activity {
  margin-top: 15px;

  .title {
    padding: 24px 30px;
  }

  .dot {
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: blue;
    border-radius: 50%;
    margin-right: 5px;
    vertical-align: middle;
  }

  .subhead {
    color: #082143;
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
  }

  .h {
    display: flex;
    justify-content: space-between;
    margin: 24px 30px;

    .el-input {
      width: 250px;
    }

    ::v-deep .el-input__inner,
    ::v-deep .el-input__icon {
      height: 44px;
      line-height: 44px;
    }

    ::v-deep .el-input__icon::before {
      height: 18px;
      line-height: 18px;
    }
  }
}

.start-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 460px;
    height: 53px;
  }
}

// .footer-btns {
//   margin-top: 50px;
//   padding-right: 130px;
// }
</style>
