<template>
  <div class="systemStatus">
    <div class="noresult" v-show="!clicked">
      <div class="image">
        <img src="@/assets/images/diagnostics_system_info.svg" alt="img"/>
      </div>
      <div class="buttonStyle">
        <el-button type="primary"
                   @click="StartObtaining" :disabled="!editable"
        >{{ $t('diagnostics.StartObtaining') }}
        </el-button>
      </div>
    </div>
    <div class="haveResult">
      <el-button
        type="primary" @click="StartObtaining" v-show="clicked" :disabled="!editable"
      >{{ $t('diagnostics.StartObtaining') }}
      </el-button>
      <div v-if="disableStart" class="beforeResultComeout">
        <p>{{ $t('diagnostics.Obtaining') }}</p>
      </div>
      <div class="showResult">
                <p v-html="result"></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import {diagnostics} from '@/plugins/enums'

export default {
  name: 'systemStatus',
  props: {
    useOldData: Boolean,
    editable: Boolean,
    recData: String,
    token: String
  },
  data() {
    return {
      disableStart: false,
      // noresult: false,
      // isbefore: true,
      smallToken: null,
      result: '',
      headData: null,
      clicked: false
    }
  },
  methods: {
    //开始获取
    async StartObtaining() {
      this.result = ''

      let params = {
        'typeCode': ['6'],
        'token': this.$route.query.token,
      }
      axios.post('/diagnostics/start', params)
        .then((data) => {
          if (!this.clicked) {
            this.clicked = true
          }
          let subData = ((data.data.result.rows[0]).recDTOMap.system_info)[0]
          this.smallToken = subData.token
          if (subData.errorInfo) {
            this.$message.error(subData.errorInfo)
          }
          this.disableStart = true
        })
      await this.sleep(8000)
      this.disableStart = false
      this.refreshData()
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    refreshData() {
      this.result = ''
      // 刷新历史列表
      let params = {
        'token': this.$route.query.token,
        'typeCode': [diagnostics().type.system_info.code],
        'status': [3, 5, 7],
        'mix': true
      }
      if (params.token) {
        axios.post('/diagnostics/readRec', params)
          .then((body) => {
            if ((body.data.result.rows)[0]) {
              let subData = (((body.data.result.rows)[0]).recDTOMap.system_info)[0]
              let dataJSON = JSON.parse(subData.data)
              this.result = dataJSON.systemInfoResultWithGoodFormat
            }
          })
      }
    },
  },
  mounted() {
    this.refreshData()
  }
}
</script>

<style lang='scss' scoped>
@font-face {
  font-family: "monofont";
  src: local("monofont"),
  url("~@/font/consola.ttf") format("truetype");
}

.noresult {
  .image {
    width: 150px;
    height: 150px;
    //background-color: green;
    margin: 0 auto;
    margin-top: 50px;
  }

  .buttonStyle {
    margin: 0 auto;
    width: 150px;
    text-align: center;
    margin-top: 10px;
  }
}

.showResult {
  p {
    margin: 0;
    margin-top: 20px;
    padding-left: 10px;
    white-space: pre-wrap;
    overflow: scroll;
    line-height: 150%;
    font-family: "monofont",serif;
    font-size: 15px;
  }
}
</style>
