// eslint-disable-next-line no-unused-vars
export function diagnostics() {
  return {
    errorType: {
      emergency: {
        code: 1
      },
      error: {
        code: 2
      },
      warning: {
        code: 3
      },
      notice: {
        code: 4
      },
      informational: {
        code: 5
      },
      debug: {
        code: 6
      }
    },
    status: {
      process: {
        code: 2
      },
      finish: {
        code: 3
      },
      error: {
        code: 4
      }
    },
    type: {
      package: {
        code: 1
      },
      syslog: {
        code: 2
      },
      screen: {
        code: 3
      },
      ping: {
        code: 4
      },
      traceroute: {
        code: 5
      },
      system_info: {
        code: 6
      }
    }
  }
}

